import { Transition } from "@headlessui/react";
import { createContext, ReactNode, useCallback, useMemo, useState } from "react";
import { AlertNotification } from "./components/AlertNotification";

interface Notification {
  context: "alert";
  id: string;
  type: "success" | "error" | "info";
  message: string;
}

interface AlertProviderValue {
  showSuccessAlert: (message: string) => void;
  showFailureAlert: (message: string) => void;
  showInfoAlert: (message: string) => void;
}

export const AlertContext = createContext<AlertProviderValue>({
  showSuccessAlert: () => {},
  showFailureAlert: () => {},
  showInfoAlert: () => {},
});

const AlertProvider = ({ children }: { children: ReactNode }) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const removeNotification = useCallback((id: string) => {
    setNotifications((prev) => prev.filter((notification) => notification.id !== id));
  }, []);

  const addNotification = useCallback(
    (type: "success" | "error" | "info", message: string) => {
      const id = window.crypto.randomUUID();
      setNotifications((prev) => [...prev, { id, type, message, context: "alert" }]);
      setTimeout(() => removeNotification(id), 5000);
    },
    [removeNotification],
  );
  const showSuccessAlert = useCallback(
    (message: string) => {
      addNotification("success", message);
    },
    [addNotification],
  );

  const showFailureAlert = useCallback(
    (message: string) => {
      addNotification("error", message);
    },
    [addNotification],
  );

  const showInfoAlert = useCallback(
    (message: string) => {
      addNotification("info", message);
    },
    [addNotification],
  );

  const memoizedValue = useMemo(
    () => ({
      showSuccessAlert,
      showFailureAlert,
      showInfoAlert,
    }),
    [showSuccessAlert, showFailureAlert, showInfoAlert],
  );

  return (
    <AlertContext.Provider value={memoizedValue}>
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 z-toast flex items-end p-2 sm:items-start sm:px-6 sm:py-16"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {notifications.map((notification) => (
            <Transition
              key={notification.id}
              enter="transition-all duration-300 ease-out"
              enterFrom="translate-y-2 opacity-0 sm:translate-x-2 sm:translate-y-0"
              enterTo="translate-y-0 opacity-100 sm:translate-x-0"
              leave="transition duration-100 ease-in"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
              show
            >
              <div className="w-full max-w-72">
                {notification.context === "alert" && (
                  <AlertNotification type={notification.type} message={notification.message} />
                )}
              </div>
            </Transition>
          ))}
        </div>
      </div>
      {children}
    </AlertContext.Provider>
  );
};

export { AlertProvider };
